import { Parser, Store, DataFactory } from 'n3'
const { namedNode } = DataFactory
import React, { useState, useEffect, useCallback, useRef } from 'react'
import ReactDOM from 'react-dom'
import LiquidMetal from 'liquidmetal'

const DEVICES_URI = new URL('./minimidi-data/devices.ttl', import.meta.url)

window.setImmediate = (func, ...rest) => setTimeout(func, 0, ...rest)

async function fetchStore (callback) {
  let devices = await (await fetch(DEVICES_URI)).text()

  let _store = new Store()
  const parser = new Parser()
  parser.parse(
    devices,
    async (error, quad, prefixes) =>
      quad
        ? _store.addQuad(quad)
        : callback(_store)
  )
}

const plural = (string, count) =>
  `${string}${count > 1 ? 's' : ''}`

// hack: convert stringy boolean to true/false
const convertTypes = literal =>
  literal &&
  literal.datatypeString &&
  literal.datatypeString === 'http://www.w3.org/2001/XMLSchema#boolean'
    ? literal.value === 'true'
      ? true
      : false
    : literal.value

const App = () => {
  const [terms, setTerms] = useState()
  const [store, setStore] = useState()

  useEffect(() => {
    fetchStore(setStore)
  }, [])

  const onChange = useCallback(event => {
    setTerms(event.target.value)
  })

  const getObjects = (subject, v) =>
    store.getObjects(subject, namedNode(`http://example.org/vocab/device/${v}`)).length
      ? convertTypes(store.getObjects(subject, namedNode(`http://example.org/vocab/device/${v}`))[0])
      : null

  const results = store
    ? store.getSubjects()
      .sort((a, b) => {
        let nameA = (getObjects(a, 'make') + ' ' + getObjects(a, 'model')).toLowerCase()
        let nameB = (getObjects(b, 'make') + ' ' + getObjects(a, 'model')).toLowerCase()

        if (nameA < nameB) {
          return -1
        }

        if (nameA > nameB) {
          return 1
        }

        return 0
      })
      .filter(subject => {
        let make = getObjects(subject, 'make')
        let model = getObjects(subject, 'model')

        if (terms && terms != '') {
          let query = terms.replace(/\s+/g, '')
          return LiquidMetal.score(make + model, query) > 0.6
        } else {
          return true
        }
      })
    : []

  let inputRef = useRef()
  let keydown = event => {
    if (event.target == document.body) {
      if (event.key == '/') {
        event.preventDefault()
        inputRef.current.focus()
      }
    }
    if (event.target == inputRef.current) {
      if (event.key == 'Escape') {
        if (event.target.value == '') {
          event.preventDefault()
          inputRef.current.blur()
        }
      }
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', keydown)
    return function unmount () {
      document.removeEventListener('keydown', keydown)
    }
  }, [])

  return <div className="lh-copy">
    <form>
    <input
      ref={inputRef}
      className="input-reset ba b--black-20 pa2 mb2 db w-100 helvetica"
      style={{ fontSize: 16 }}
      type="search"
      placeholder={"Filter by Make or Model"}
      onChange={onChange}
      // autoFocus={true}
      aria-label="Filter by Make or Model to find a device with minijack TRS MIDI support">
    </input>
    </form>
    {
      store &&
      <div
        style={{ height: 300 }}
        className="results-listing flex flex-column helvetica pb5 overflow-scroll">
        {
          results.length < 1
            ? <div className="pa3 mr2 pb0 pb3-ns lh-title">
                <span className="f7">
                  No matches found.
                </span>
                <br />
                <span className="f7 gray">
                  Are we missing a device? Please <a className="blue link" href="https://github.com/audionerd/minimidi-data/issues">let us know</a>.
                </span>
              </div>
            : results
              .map((subject, n) => {
                let get = v => getObjects(subject, v)

                let make = get('make')
                let model = get('model')
                let typeA = get('typeA')
                let typeB = get('typeB')
                let typeTS = get('typeTS')
                let inputs = get('inputs')
                let outputs = get('outputs')
                let thrus = get('thrus')
                let uri = get('uri')
                let notes = get('notes')
                let details = get('details')

                let zebra = n % 2 === 0

                return <div
                  key={subject.id}>
                  <div
                    style={{
                      background: zebra ? '#f8f8f8' : 'transparent'
                    }}
                    className={`flex flex-wrap items-start`}>
                      <div className="flex flex-column items-start w-100 w-30-ns">
                        <div className="pa3 mr2 pb0 pb3-ns">
                          <div className="black-60 ttu tracked f7">{make}</div>
                          {
                            uri
                              ? <div className="f6">
                                  <a className="no-underline blue link" href={uri}>
                                    {model}
                                  </a>
                                </div>
                              : <div>{model}</div>
                          }
                        </div>
                      </div>
                      <div className="f7 w-20-ns">
                        <div className="midi-pill">
                          {typeA && typeB && <div className="ma3 mv2 mv3-ns ph2 pv1 lh-title br-pill washed-blue bg-blue dib">Types A <span className="avenir">&amp;</span> B</div>}
                          {typeA && !typeB && <div className="ma3 mv2 mv3-ns ph2 pv1 lh-title br-pill washed-blue bg-blue dib">Type A</div>}
                          {typeB && !typeA && <div className="ma3 mv2 mv3-ns ph2 pv1 lh-title br-pill washed-blue bg-blue dib">Type B</div>}
                          {typeTS && <div className="ma3 mv2 mv3-ns ph2 pv1 lh-title br-pill washed-blue bg-blue dib">TS</div>}
                          {!typeA && !typeB && !typeTS && <div className="ma3 mv2 mv3-ns pv1 lh-title gray dib">Unknown Type</div>}
                        </div>
                      </div>
                      <div className="f7 w-20-ns">
                        {
                          inputs != null && inputs > 0 && outputs != null && outputs > 0 && thrus !== null & thrus > 0
                            ? <div className="ma3 mv2 mv3-ns pt1 pt0-ns">MIDI IN/OUT/Thru</div>
                            : inputs != null && inputs > 0 && outputs != null && outputs > 0
                              ? <div className="ma3 mv2 mv3-ns pt1 pt0-ns">MIDI IN/OUT</div>
                              : <div>
                                {inputs != null && inputs > 0 && thrus != null && thrus > 0 && <div className="ma3 mv2 mv3-ns pt1 pt0-ns">MIDI IN/THRU</div>}
                                {inputs != null && inputs > 0 && (thrus == null || thrus == 0) && <div className="ma3 mv2 mv3-ns pt1 pt0-ns">MIDI IN</div>}
                                {outputs != null && outputs > 0 && <div className="ma3 mv2 mv3-ns pt1 pt0-ns">MIDI OUT</div>}
                              </div>
                        }
                      </div>
                      <div className="f7 w-100 w-30-ns ph3 pa3-ns black-70 overflow-scroll">
                        {
                          notes &&
                            <p className="mt0">{notes}</p>
                        }
                        {
                          details &&
                            <p className="mt0">{details}</p>
                        }
                      </div>
                    </div>
                  </div>
              })
            }
      </div>
    }
  </div>
}

ReactDOM.render(<App />, document.getElementById('app'))
